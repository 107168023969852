import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, CircularProgress, Grid, IconButton, Collapse } from '@mui/material';
import { Assignment, CheckCircle, Cancel, ExpandMore, ExpandLess } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import proxy from '../../api/axios';

const PreviousChallenges = () => {
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);  // State to manage collapsed view

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const response = await proxy.get('/challenge/previous-daily-challenges');
        setChallenges(response.data.previousChallenges || []);
      } catch (err) {
        setError('Failed to load previous challenges.');
      } finally {
        setLoading(false);
      }
    };

    fetchChallenges();
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <div style={{ textAlign: 'center', color: 'red', fontSize: '1.2rem', marginTop: '2rem' }}>{error}</div>;
  }

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 2 }}>
        {challenges?.length > 0 && 'Previous Challenges'}
        <IconButton onClick={handleToggle} style={{ marginLeft: '16px' }}>
          {isCollapsed ? <ExpandMore /> : <ExpandLess />}
        </IconButton>
      </Typography>

      <Collapse in={!isCollapsed}>
        <Grid container spacing={3}>
          {challenges?.map((challenge, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Link to={`/problems/${challenge.slug}`} style={{ textDecoration: 'none' }}>
                <Card
                  sx={{
                    boxShadow: 3,
                    borderRadius: '8px',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                    }
                  }}
                >
                  <CardContent>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <IconButton color="primary">
                        <Assignment fontSize="large" />
                      </IconButton>
                      <Typography variant="h6" color="primary">
                        {`Day #${challenge.day}`}
                      </Typography>
                    </div>

                    <Typography
                      variant="h5"
                      style={{ fontSize: '1.1rem', fontWeight: 'bold' }}
                      gutterBottom
                    >
                      {challenge.title}
                    </Typography>

                    <Typography
                      variant="body2"
                      component="div"
                      style={{ fontSize: '0.9rem', color: '#555', height: '60px', overflow: 'hidden' }}
                      dangerouslySetInnerHTML={{ __html: challenge.description.slice(0, 100) + '...' }}
                    />

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {challenge.isSolved ? (
                        <CheckCircle fontSize="small" style={{ color: 'green', marginRight: '8px' }} />
                      ) : (
                        <Cancel fontSize="small" style={{ color: 'red', marginRight: '8px' }} />
                      )}
                      <Typography
                        variant="body2"
                        style={{
                          fontSize: '0.8rem',
                          color: challenge.isSolved ? 'green' : 'red',
                        }}
                      >
                        {challenge.isSolved ? 'Solved' : 'Unsolved'}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </>
  );
};

export default PreviousChallenges;
