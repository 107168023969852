import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  IconButton,
  Modal,
  Tooltip,
  CircularProgress,
  MenuItem,
  useTheme,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import { Editor } from '@monaco-editor/react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsIcon from '@mui/icons-material/Settings';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { defaultCode, languageMapping } from '../common/constants';
import proxy from '../api/axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const CompilerPage = () => {
  const appTheme = useTheme();
  const { link } = useParams();
  const [language, setLanguage] = useState('java');
  const [code, setCode] = useState(defaultCode['java']);
  const [customInput, setCustomInput] = useState('');
  const [output, setOutput] = useState('');
  const [error, setError] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isCompiling, setIsCompiling] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [fontSize, setFontSize] = useState(14);
  const [theme, setTheme] = useState('vs-dark');
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [codeLink, setCodeLink] = useState('https://crafterhack.com/ide');

  useEffect(() => {
    const fetchCode = async () => {
      try {
        const { data } = await proxy.get(`/compiler-codes/${link}`);
        setCode(data.code);
      } catch (err) {
        toast.error('Error fetching code');
        console.error('Error generating link:', err);
      }
    };
  
    if (link) {
      fetchCode(); // Call the async function inside useEffect
    }
  }, [link]); // Dependency on 'link'
  

  const handleCodeChange = (newCode) => {
    setCode(newCode);
    localStorage.setItem(`compiler-code-${language}`, newCode || defaultCode[language]); // Persist the code for the selected language
  };

  const handleRunCode = async () => {
    setOutput('');
    setError(null);
    setIsCompiling(true);
    try {
      const requestBody = {
        source_code: code,
        language_id: languageMapping[language],
        testCase: {
          input: customInput
        }
      };

      const { data } = await proxy.post('/code/compile', requestBody, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setOutput(data.message);
      setError(data.status !== 'Accepted');
    } catch (error) {
      console.error("Error:", error);
      setError("Error occurred while running the code.");
    } finally {
      setIsCompiling(false);
    }
  };

  const handleResetCode = () => {
    setCode(defaultCode[language]);
    localStorage.setItem(`compiler-code-${language}`, defaultCode[language]);
  };

  const handleSettingsOpen = () => {
    setOpenSettings(true);
  };

  const handleSettingsClose = () => {
    setOpenSettings(false);
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(codeLink);
      setCopySuccess('Link copied!');
    } catch (err) {
      setCopySuccess('Failed to copy the link.');
    }
  };

  const handleClose = () => {
    setShareModalOpen(false);
    setCopySuccess(''); // Reset copy message
  };
  const handleShareCode = async () => {
    try {
      const { data } = await proxy.post('/compiler-codes',{code});
      setCodeLink('https://crafterhack.com/ide/'+data.link);
      setShareModalOpen(true);
    } catch(err){
      toast.error('Error generating link');
      console.log('Error generating link');
    } 
  }


  return (
    <Box sx={{ padding: '20px', width: '90%', margin: '0 auto' }}>
      <Helmet>
        <title>CrafterHack IDE | Online {language} Compiler | Share code</title>
        <meta name="description" content={`This is the page for compiling the code in javascript, java, python or c++. You can share your code also`} />
      </Helmet>
      {!isFullscreen && (<Typography
        variant="h4"
        sx={{
          mb: 2,
          textAlign: 'left',
          fontFamily: 'Courier New, Courier, monospace',
          color: '#4CAF50',
          fontSize: '1.5rem',
        }}
      >
        IDE/Compiler
      </Typography>)}
      

      <Grid container spacing={2}>
        {/* Left Column: Monaco Editor */}
        <Grid item xs={12} md={ isFullscreen ? 12 : 7}>
          <Paper sx={{ padding: '10px', position: 'relative' }}>
            <PerfectScrollbar style={{ height: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.3 }}>
                <TextField
                  select
                  label="Lang"
                  value={language}
                  onChange={(e) => {
                    setLanguage(e.target.value);
                    setCode(localStorage.getItem(`compiler-code-${e.target.value}`) || defaultCode[e.target.value]);
                    localStorage.setItem('lang', e.target.value);
                  }}
                  sx={{ height: '30px', marginRight: '10px' }}
                  InputProps={{
                    style: { height: '30px' },
                  }}
                >
                  <MenuItem value="java">Java</MenuItem>
                  <MenuItem value="python">Py</MenuItem>
                  <MenuItem value="cpp">C++</MenuItem>
                  <MenuItem value="javascript">JS</MenuItem>
                </TextField>

                <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Tooltip title="Share this Code">
                  <IconButton onClick={handleShareCode} color="inherit" aria-label="share">
                    <ShareIcon />
                  </IconButton>
                  </Tooltip>
                  <Tooltip title="Reset Code">
                    <IconButton onClick={handleResetCode} sx={{ marginRight: '10px' }}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Settings">
                    <IconButton onClick={handleSettingsOpen}>
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={isFullscreen ? 'Exit from Full Screen' : 'Switch to Full Screen'}>
                    <IconButton onClick={toggleFullscreen} sx={{ marginRight: '10px' }}>
                      {isFullscreen ? <CloseFullscreenIcon /> : <FullscreenIcon />}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              <Editor
                height="calc(99vh - 150px)"
                width="100%"
                language={language}
                value={code}
                onChange={handleCodeChange}
                options={{
                  selectOnLineNumbers: true,
                  theme: theme,
                  fontSize: fontSize,
                }}
              />
            </PerfectScrollbar>
          </Paper>
        </Grid>

       
        <Grid item xs={12} md={ isFullscreen ? 12 : 5}>
          <Paper sx={{ padding: '10px'}}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Run Code
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Button
                variant="contained"
                color="success"
                onClick={handleRunCode}
                disabled={isCompiling}
                startIcon={!isCompiling && <PlayArrowIcon />}
                sx={{ marginBottom: '10px', width: 200 }}
              >
                {isCompiling ? (
                  <>
                    <CircularProgress style={{ marginRight: 10 }} size={20} color="inherit" /> Running
                  </>
                ) : (
                  'Run'
                )}
              </Button>

              <TextField
                label="Input (stdin)"
                multiline
                minRows={7}
                value={customInput}
                onChange={(e) => setCustomInput(e.target.value)}
                sx={{ marginBottom: '10px', mt: 2 }}
                variant="filled"
              />

              {output && ( // Only show output section if output is available
                <>
                  <Typography variant="h6">Output (stdout)</Typography>
                  <Paper
                    sx={{
                      padding: '10px',
                      marginTop: '10px',
                      backgroundColor: error ? '#881c1c' : '#03523b',
                      color: 'white',
                      flexGrow: 1,
                      whiteSpace: 'pre-wrap',
                      maxHeight: 200
                    }}
                  >
                    <Typography variant="body1">{output || 'No output yet.'}</Typography>
                  </Paper>
                </>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Modal
        open={shareModalOpen}
        onClose={handleClose}
        aria-labelledby="share-modal-title"
        aria-describedby="share-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="share-modal-title" variant="h6" component="h2" gutterBottom>
            Share Code Link
          </Typography>

          <Typography id="share-modal-description" sx={{ mb: 2 }}>
            This link will be active for 1 year.
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <span>{codeLink}</span>
            <IconButton color="primary" onClick={handleCopyLink} sx={{ ml: 1 }}>
              <ContentCopyIcon />
            </IconButton>
          </Box>

          {/* Copy Success Message */}
          {copySuccess && (
            <Typography variant="body2" color="success.main" sx={{ mb: 2 }}>
              {copySuccess}
            </Typography>
          )}

          {/* Close Button */}
          <Button
            variant="contained"
            onClick={handleClose}
            startIcon={<CloseIcon />}
            fullWidth
          >
            Close
          </Button>
        </Box>
      </Modal>

      <Modal open={openSettings} onClose={handleSettingsClose}>
        <Box
          sx={{
            ...{ backgroundColor: appTheme.palette.mode === 'dark' ? '#333' : '#fff' },
            padding: '20px',
            borderRadius: '8px',
            boxShadow: 3,
            width: 400,
            margin: 'auto',
            marginTop: '100px',
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
            Editor Settings
          </Typography>
          <TextField
            select
            label="Theme"
            value={theme}
            onChange={(e) => setTheme(e.target.value)}
            sx={{ mb: 2, width: '100%' }}
          >
            <MenuItem value="vs-dark">Dark</MenuItem>
            <MenuItem value="vs-light">Light</MenuItem>
          </TextField>
          <TextField
            select
            label="Font Size"
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
            sx={{ mb: 2, width: '100%' }}
          >
            {[12, 13, 14, 16, 18, 20].map((size) => (
              <MenuItem key={size} value={size}>
                {size}px
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Modal>
      <br/><br/>
      <Typography
        variant="body1"
        sx={{
          mb: 2,
          textAlign: 'left',
          color: '#777',
          fontSize: '1rem',
        }}
      >
        Welcome to the CrafterHack Compiler, a versatile online code editor that supports multiple programming languages.
        Write, compile, and run your code with ease, all in one place. Whether you're debugging, testing, or just coding for fun,
        our user-friendly interface and powerful features will help you achieve your coding goals.
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mt: 3,
          textAlign: 'center',
          color: '#777',
          fontSize: '0.9rem',
        }}
      >
        Our compiler is designed for both beginners and seasoned developers,
        providing an excellent environment for learning and experimentation.
      </Typography>
    </Box>
  );
};

export default CompilerPage;
