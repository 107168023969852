export const formatString = (str) => {
  // Replace newline characters with <br />
  // Replace tab characters with a fixed width space
  return str
    .replace(/\n/g, '<br />') // Convert newlines to <br />
    .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;'); // Convert tabs to spaces (4 spaces here)
};


export const getToken = () => {
  return localStorage.getItem('token') || '';
}

export const formatDateTime = (dateTime) => {
  const dateObj = new Date(dateTime);
  const date = dateObj.toLocaleDateString();
  const time = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  return { date, time };
};

// Utility function to shuffle an array
export const shuffleArray = (array) => {
  return array?.sort(() => Math.random() - 0.5);
};

export const generateRandomString = (length = 8) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyz';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
}